const RESET_TIMEOUT_MILLIS = 3000;
const CONFIRMATION_MESSAGE = 'Click to confirm...';

import { Controller } from "stimulus"

export default class extends Controller {

    static targets = ['link'];

    connect() {
        this.delete = false;
        // console.log('Delete Connected')
    }

    click(event) {
        if (this.delete) {
            let replace = this.linkTarget.getAttribute('data-replace');
            // console.log(replace);
            //debugger;
            event.preventDefault();
            event.stopImmediatePropagation();
            var _this = this;
            var url = this.linkTarget.getAttribute('href');
            let contact_id = $(event.target).attr('data-contactid');
            $.ajax({
                url: url,
                method:'DELETE',
                credentials: 'include',
                success: function(html) {
                    if (replace === 'true'){
                        $(_this.linkTarget).closest('.edit-address-section').replaceWith(html);
                    } else {
                        _this.element.parentNode.style = 'display: none;';
                        $('#contacts-list').append(html);
                        var evt = $.Event('contactsChanged');
                        $(window).trigger(evt);
                        clearTimeout(this.timeout);
                        _this.element.parentNode.remove();
                    }
                }
            });

        } else {
            this.oldMessage = this.linkTarget.innerHTML;
            this.linkTarget.innerHTML = CONFIRMATION_MESSAGE;
            this.delete = true;
            this.timeout = setTimeout(() => {
                this.resetState();
            }, RESET_TIMEOUT_MILLIS);
            event.preventDefault();
            event.stopImmediatePropagation();
            return false;
        }
    }

    handleSuccess(event) {
        console.log('removing element');
        clearTimeout(this.timeout);
        this.element.parentNode.remove();
    }

    handleError(event) {
        console.log('error removing element');
        clearTimeout(this.timeout);
        this.resetState();
        this.element.style = '';
    }

    resetState() {
        if (this.delete) {
            this.linkTarget.removeEventListener('ajax:success', this.handleSuccess.bind(this));
            this.linkTarget.removeEventListener('ajax:error', this.handleError.bind(this));
            this.linkTarget.innerHTML = this.oldMessage;
            this.delete = false;
        }
    }
}