import { Controller } from "stimulus"

export default class extends Controller {

    static targets = ['card', 'cancelSave', 'submit', 'edit' ];

    connect() {
        console.log('education_controller connected');
        $(this.scope.element).find('[data-toggle="tooltip"]').tooltip({
                html: true,
                placement: 'bottom'
            }
        );
        $('.help-dialog').on('click', function(event){
            event.stopImmediatePropagation();
            var target = $(this).data('target');
            var dialog = $('#'+target+'.explanation');
            if (dialog.dialog('isOpen')){
                dialog.dialog('close');
            } else {
                dialog.dialog('open');
            }
        });

        $(":input").inputmask();

        var _this = this;
        $('input,select,textarea')
            .on('change', function(){
                _this.checkRequired();
                _this.removeNotices();
            });


        this.checkRequired();
        this.removeNotices();
    }

    submitEducation(event) {
        event.preventDefault();
        let form = this.editTarget;
        this.submit(event,form);
    }

    cancelSave(event) {
        var url = this.cancelSaveTarget.getAttribute('href');
        event.preventDefault();
        fetch(url, {
            credentials: 'include',
        })
            .then(response => response.text())
            .then(html => {
                $('#education-edit').replaceWith(html);
            })
    }

    checkRequired() {
        let submitTarget = this.submitTarget;
        let form = this.editTarget;
        $(form).find('input,select').attr('disabled', false); // first make everything editable
        $(form).find('input,select').filter('[required]:hidden').attr('disabled', true); //then disable hidden

        //console.log(form)
        form.classList.remove('was-validated');
        form.classList.add('was-validated');
        let isValid = form.checkValidity();
        if ( isValid === false)  {
            // console.log('invalid');
            $(submitTarget)
                .val('Missing Data')
                .removeClass('btn-success').addClass('btn-warning')
        } else {
            //console.log('valid');
            $(submitTarget)
                .val('Save Changes')
                .addClass('btn-success')
                .removeClass('btn-warning')
                .attr('disabled', false)
        }
    }

    removeNotices(event) {
        let scope = this.element.parentNode;
        let forms = $(scope).find('.needs-validation');
        $(forms).removeClass('was-validated');
    }

    submit(event,form) {
        event.preventDefault();
        //event.stopImmediatePropagation();

        //console.log(form);

        if ( !form.checkValidity() ){
            return false;
        }

        let url = $(form).attr('action');
        let data = $(form).serialize();
        //console.log(data)
        $.ajax({
            url: url,
            type: 'patch',
            data: data,
            beforeSend: () => this._beforeSend(),
            success: function(data) {
                $('#education-edit').replaceWith(data);
                var evt = $.Event('educationChanged');
                $(window).trigger(evt);
            }

        })
    }

    showFNSBSD(event){
        var block = $('#previous-fnsbsd,#previous-education');
        block.find('input,select').filter('[required]').removeAttr('disabled');
        block.removeClass('hidden');
        this.checkRequired();
        this.removeNotices();
    }

    hideFNSBSD(event){
        var block = $('#previous-fnsbsd');
        block.find('input,select').filter('[required]').attr('disabled', true);
        block.addClass('hidden');
        this.checkRequired();
        this.removeNotices();
    }

    showPrevEd(event){
        var block = $('#previous-education');
        block.removeClass('hidden');
        block.find('input,select').filter('[required]').removeAttr('disabled');
        this.checkRequired();
        this.removeNotices();
    }

    hidePrevEd(event){
        var block = $('#previous-education');
        block.find('input,select').filter('[required]').attr('disabled', true);
        block.addClass('hidden');
        this.checkRequired();
        this.removeNotices();
    }

    showForeign(event){
        var block = $('#foreign-education');
        block.removeClass('hidden');
        block.find('input,select').filter('[required]').removeAttr('disabled');
        this.checkRequired();
        this.removeNotices();
    }

    hideForeign(event){
        var block = $('#foreign-education');
        block.find('input,select').filter('[required]').attr('disabled', true);
        block.addClass('hidden');
        this.checkRequired();
        this.removeNotices();
    }

    showOtherLang(event){
        var block = $('#other-language');
        block.removeClass('hidden');
        block.find('input,select').filter('[required]').removeAttr('disabled');
        this.checkRequired();
        this.removeNotices();
    }

    hideOtherLang(event){
        var block = $('#other-language');
        block.find('input,select').filter('[required]').attr('disabled', true);
        block.addClass('hidden');
        this.checkRequired();
        this.removeNotices();
    }

    handle_FNSBSD_other(event){
        var sch = $(event.target).find(':selected').val();

        var block = $('#fnsbsd-school-select');
        if (sch === 'Other') {
           block.removeClass('hidden');
           block.find('input,select').filter('[required]').removeAttr('disabled');
        } else {
            block.find('input,select').filter('[required]').attr('disabled', true);
            block.addClass('hidden');
        }
        this.checkRequired();
        this.removeNotices();
    }

    handleForeignLang(event){
        var lang = $(event.target).find(':selected').val();

        var block = $('#other-instruction-lang');
        if (lang === 'Other') {
           block.removeClass('hidden');
            block.find('input,select').filter('[required]').removeAttr('disabled');
        } else {
            block.find('input,select').filter('[required]').attr('disabled', true);
            block.addClass('hidden');
        }
        this.checkRequired();
        this.removeNotices();
    }

    handleFirstLang(event){
        var lang = $(event.target).find(':selected').val();

        var block = $('#first-lang');
        if (lang === 'Other') {
           block.removeClass('hidden');
            block.find('input,select').filter('[required]').removeAttr('disabled');
        } else {
            block.find('input,select').filter('[required]').attr('disabled', true);
            block.addClass('hidden');
        }
        this.checkRequired();
        this.removeNotices();
    }

    handlePrefLang(event){
        var lang = $(event.target).find(':selected').val();

        var block = $('#pref-lang');
        if (lang === 'Other') {
           block.removeClass('hidden');
            block.find('input,select').filter('[required]').removeAttr('disabled');
        } else {
            block.find('input,select').filter('[required]').attr('disabled', true);
            block.addClass('hidden');
        }
        this.checkRequired();
        this.removeNotices();
    }

    handleOtherLang(event){
        var lang = $(event.target).find(':selected').val();

        var block = $('#other-lang');
        if (lang === 'Other') {
           block.removeClass('hidden');
            block.find('input,select').filter('[required]').removeAttr('disabled');
        } else {
            block.find('input,select').filter('[required]').attr('disabled', true);
            block.addClass('hidden');
        }
        this.checkRequired();
        this.removeNotices();
    }

    _beforeSend() {
        $(this.submitTarget)
            .val('saving...')
            .attr('disabled', true)
    }

}