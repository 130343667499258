import { Controller } from "stimulus"

export default class extends Controller {

    static targets = [ "section", 'demographics', 'school' ];

    connect() {
        console.log('reg_controller connected');

        $(this.scope.element).find('[data-toggle="tooltip"]').tooltip({
                html: true,
                placement: 'bottom'
            }
        );

        $( '.explanation' ).dialog({
            autoOpen: false,
            minWidth: 500,
            hide: { effect: "blind", duration: 200 },
            show: { effect: "blind", duration: 200 }
        });

        $('a.help-dialog-link').on('click', function(event){
            event.stopPropagation();
        });

        $('.help-dialog').on('click', function(event){
            event.stopImmediatePropagation();
            var target = $(this).data('target');
            var dialog = $('#'+target+'.explanation');
            if (dialog.dialog('isOpen')){
                dialog.dialog('close');
            } else {
                dialog.dialog('open');
            }
        });


        $(window).on('statusUpdate', function(event) {
            console.log('reg_controller detected event: ' + event.type + ' from: ' + event.target_div);
            console.log(event);
            $(event.target_div+' a').removeClass('section-complete section-incomplete').addClass(event.status);
            if ( event.target_div === '#signature-nav' && event.status === 'section-complete') {
              $('#done-wrapper').removeClass('hidden');
            }
        });

        $(window).on('demographicsChanged', function(event){
            console.log('reg_controller detected event: '+event.type + ' from: ' + event.target_div);
            let reg_id = window.location.pathname.replace(/[^\d]/g, '');
            var url = '/regs/'+reg_id+'/help';
            $('.ui-dialog').remove();
            $.get(url, function(data){
                $('#help').html(data);
                $( '.explanation' ).dialog({
                    autoOpen: false,
                    minWidth: 500,
                    hide: { effect: "blind", duration: 200 },
                    show: { effect: "blind", duration: 200 }
                });

            });
        });

        $(window).on('householdsChanged gradeLevelChanged contactsChanged', function(event){
            console.log('reg_controller detected: '+event.type + ' from: ' + event.target_div);
            $('#school').html('<div class="col-12 mt-2 mb-2 text-faded"><div class="text-center align-middle" style="width:100%"><h2>Finding Area School...</h2><i class="fa fa-spin fa-spinner text-faded-darker fa-4x"></div></div>');
            let reg_id = window.location.pathname.replace(/[^\d]/g, '');
            var url = '/regs/'+reg_id+'/school_lookup';
            $.get(url, function(data){
                var url = '/regs/'+reg_id+'/school';
                // console.log(url);
                fetch(url, {
                    credentials: 'include',
                })
                    .then(response => response.text())
                    .then(html => {
                        //console.log(html);
                        $('#school').html(html);
                    });

                if ($('#family-wrapper').is(':visible') && $('#school-wrapper').is(':hidden')) {
                    $('#school-wrapper').removeClass('hidden').slideDown();
                    $('#school-nav').removeClass('hidden').slideDown();
                }
            });
        });

        $(window).on('schoolChanged', function(event){
            console.log('reg_controller detected: '+event.type + ' from: ' + event.target_div);
            $('#documentation-wrapper,#ferpa-wrapper,#docs-wrapper').removeClass('hidden');
            $('#documentation-nav,#ferpa-nav,#docs-nav').removeClass('hidden');
        });

        $(window).on('documentationChanged aneChanged', function(event){
            console.log('reg_controller detected: '+event.type + ' from: ' + event.target_div);
            let reg_id = window.location.pathname.replace(/[^\d]/g, '');
            var url = '/regs/'+reg_id+'/signature/edit';
            $.get({
                url:url,
                success: function(html) {
                    $('#signature').html(html);
                    $('#signature-wrapper,#signature,#signature-nav').removeClass('hidden');
                }
            });
        });


        $(window).on('householdsChanged gradeLevelChanged contactsChanged ferpaChanged documentationChanged docsChanged schoolChanged homelessChanged migrantChanged aneChanged eslChanged spedChanged educationChanged signatureChanged', function(event){
            console.log('reg_controller detected event: '+event.type + ' from: ' + event.target_div);
            let reg_id = window.location.pathname.replace(/[^\d]/g, '');
            var url = '/regs/'+reg_id+'/reg_status';
            $.get({
                url:url,
                success: function(html) {
                    $('#updates').html(html);
                }
            });
        });

        $('.hover-info')
            .on('mouseenter', function(){
                var note = $(this).find('.fa').attr('note');
                //console.log(note);
                $(this).closest('.editable').find('.hover-note').html(note);
            })
            .on('mouseleave', function(){
                $(this).closest('.editable').find('.hover-note').html('');
            });


    }

    updateMenu(event) {
        var section = $(event.target).attr('data-name');
        $('#navbarProgress li').removeClass('active');
        $('#navbarProgress li[data-section='+section+']').addClass('active');

    }

    displayEditDemographics(event) {
        var url = $('#student').attr('data-url');
        fetch(url, {
            credentials: 'include',
        })
            .then(response => response.text())
            .then(html => {
                $('#student').replaceWith(html);
            })
    }

    displayEditFerpa(event) {
        var target = $(event.target).closest('.editable');
        var url = target.attr('data-url');
        fetch(url, {
            credentials: 'include',
        })
            .then(response => response.text())
            .then(html => {
                $('#ferpa-button').replaceWith(html)
            })
    }

    displayEditDocs(event) {
        var target = $(event.target).closest('.editable');
        var url = target.attr('data-url');
        fetch(url, {
            credentials: 'include',
        })
            .then(response => response.text())
            .then(html => {
                $('#docs-button').replaceWith(html)
            })
    }

    displayEditSchool(event) {
        var target = $(event.target).closest('.editable');
        var url = target.attr('data-url');
        fetch(url, {
            credentials: 'include',
        })
            .then(response => response.text())
            .then(html => {
                $('#school').html(html)
            })
    }

    scrollToSection(event) {
        var target = $(event.target).closest('li');
        var nav_id = $(target).attr('id');
        var section_id = nav_id.replace('-nav','-section');
        var section = $('#'+section_id);
        section[0].scrollIntoView(true);
        window.scrollBy(0, -55);
        $('#navbarProgress li').removeClass('active');
        $('#'+nav_id).addClass('active');
    }


}