import { Controller } from "stimulus"

export default class extends Controller {

    static targets = ['card', 'cancelSave', 'submit', 'edit' ];

    connect() {
        console.log('sped_controller connected');
        $(this.scope.element).find('[data-toggle="tooltip"]').tooltip({
                html: true,
                placement: 'bottom'
            }
        );

        var _this = this;


        $(_this.editTarget).find('input,select,textarea').filter('[required]').each(function(){
            if ($(this).is(':hidden')) {
                $(this).attr('disabled', true)
            }
        });

        $('input,select,textarea')
            .on('change', function(){
                _this.checkRequired();
                _this.removeNotices();
            });

        this.checkRequired();
        this.removeNotices();
    }

    submitSped(event) {
        event.preventDefault();
        let form = this.editTarget;
        this.submit(event,form);
    }

    cancelSave(event) {
        var url = this.cancelSaveTarget.getAttribute('href');
        event.preventDefault();
        fetch(url, {
            credentials: 'include',
        })
            .then(response => response.text())
            .then(html => {
                $('#sped-edit').replaceWith(html);
            })
    }

    checkRequired() {
        if ( $('#sped-additional :checkbox:checked').length === 0 ){
            $('#sped-additional :checkbox').each(function(){
                // console.log(this);
                this.setCustomValidity('This field is required');
            })
        } else {
            $('#sped-additional :checkbox, #sped-additional textarea').each(function(){
                // console.log(this);
                this.setCustomValidity('');
            })
        }
        let submitTarget = this.submitTarget;
        let form = this.editTarget;

        //console.log(form)
        form.classList.remove('was-validated');
        form.classList.add('was-validated');
        let isValid = form.checkValidity();
        if ( isValid === false)  {
            // console.log('invalid');
            $(submitTarget)
                .val('Missing Data')
                .removeClass('btn-success').addClass('btn-warning')
        } else {
            // console.log('valid');
            $(submitTarget)
                .val('Save Changes')
                .addClass('btn-success')
                .removeClass('btn-warning')
                .attr('disabled', false)
        }
    }

    removeNotices(event) {
        let scope = this.element.parentNode;
        let forms = $(scope).find('.needs-validation');
        $(forms).removeClass('was-validated');
    }

    submit(event,form) {
        event.preventDefault();

        if ( !form.checkValidity() ){
            return false;
        }

        let url = $(form).attr('action');
        let data = $(form).serialize();
        //console.log(data)
        $.ajax({
            url: url,
            type: 'patch',
            data: data,
            beforeSend: () => this._beforeSend(),
            success: function(data) {
                $('#sped-edit').replaceWith(data);
                var evt = $.Event('spedChanged');
                $(window).trigger(evt);
            }

        })
    }


    displayOther(event){
        var block = $('#other-sped');

        if ( $('#reg_special_services_other').is(':checked') ){
            block.removeClass('hidden');
            block.find('textarea').removeAttr('disabled');
        } else {
            block.find('textarea').attr('disabled', true);
            block.addClass('hidden');
        }
        this.checkRequired();
        this.removeNotices();
    }

    showAdditional(event){
        var block = $('#sped-additional');
        block.find('input,select').removeAttr('disabled');
        block.removeClass('hidden');
        if ( $('#reg_special_services_other').is(':checked') ){
            block.find('textarea').removeAttr('disabled');
        } else {
            block.find('textarea').attr('disabled', true);
        }
        this.checkRequired();
        this.removeNotices();
    }

    hideAdditional(event){
        var block = $('#sped-additional');
        block.find('input,textarea').attr('disabled', true);
        block.addClass('hidden');
        if ( $('#reg_special_services_other').is(':checked') ){
            block.find('textarea').removeAttr('disabled');
        } else {
            block.find('textarea').attr('disabled', true);
        }
        this.checkRequired();
        this.removeNotices();
    }

    _beforeSend() {
        $(this.submitTarget)
            .val('saving...')
            .attr('disabled', true)
    }

}