import { Controller } from "stimulus"

export default class extends Controller {

    static targets = ['card', 'edit', 'cancelSave', 'submit' ];

    connect() {
        console.log('household_controller connected');

        $(this.scope.element).find('[data-toggle="tooltip"]').tooltip({
                html: true,
                placement: 'bottom'
            }
        );

        $('input[name*="employer"]').filter(function () {
            return !!this.value;
        }).each(function(){
            console.log(this);
            $(this).closest('.row').find('.employer').removeClass('hidden').attr('disabled', false)
        });

        $('.help-dialog').on('click', function(event){
            event.stopImmediatePropagation();
            var target = $(this).data('target');
            var dialog = $('#'+target+'.explanation');
            if (dialog.dialog('isOpen')){
                dialog.dialog('close');
            } else {
                dialog.dialog('open');
            }
        });

        var _this = this;
        $('select')
            .on('change', function(){
                if ($(this).prop('type') === 'select-one' && $(this).find(":selected").val() === 'OTHER' ){
                   $(this).closest('.row').find('.military-other').removeClass('hidden');
                   $(this).closest('.row').find('input').removeAttr(':selected').attr('disabled', false);
                } else {
                    $(this).closest('.row').find('.military-other').addClass('hidden');
                }
                _this.checkRequired();
                _this.removeNotices();
            });

        $('input,textarea')
            .on('change', function(){
                _this.checkRequired();
                _this.removeNotices();
            });

        this.checkRequired();
        this.removeNotices();
    }

    submitHousehold(event) {
        event.preventDefault();
        let form = this.editTarget;
        this.submit(event,form);
    }

    cancelSave(event) {
        var url = this.cancelSaveTarget.getAttribute('href');
        event.preventDefault();
        fetch(url, {
            credentials: 'include',
        })
            .then(response => response.text())
            .then(html => {
                this.cardTarget.outerHTML = html
            })
    }

    displayEmployer(event) {
        var mil = $(event.target);
        $(mil).closest('.row').find('.mil-select').addClass('hidden');
        $(mil).closest('.row').find('.mil-select input,.mil-select select').attr('disabled', true);
        var selection = $(mil).find(':selected').text();
        var active = /United States Military/;
        var reserve = /Active:/;
        // var inactive = /Reserve Force|Inactive:/;
        var other = /Civilian/;
        var employer = /Inactive: Reserve Force|Inactive:|Commissioned|None of the above|Veteran/;

        if ( active.test(selection) === true ) {
          $(mil).closest('.row').find('.mil-active').removeClass('hidden');
          $(mil).closest('.row').find('select').attr('disabled', false);
        }
        if ( reserve.test(selection) === true ) {
            $(mil).closest('.row').find('.mil-reserve').removeClass('hidden');
            $(mil).closest('.row').find('select').attr('disabled', false);
        }
        if ( other.test(selection) === true ) {
            $(mil).closest('.row').find('.mil-other').removeClass('hidden');
            $(mil).closest('.row').find('select').attr('disabled', false);
        }
        if ( employer.test(selection) === true ) {
            $(mil).closest('.row').find('.employer').removeClass('hidden');
            $(mil).closest('.row').find('input').attr('disabled', false);
        }
    }

    checkRequired() {
        let submitTarget = this.submitTarget;
        let form = this.editTarget;
        //console.log(form)
        form.classList.remove('was-validated');
        form.classList.add('was-validated');

        $(form).find('input,select').filter('[required]').each(function() {
            if ($(this).is(':hidden')) {
                $(this).attr('disabled', true)
            }
        });

        let isValid = form.checkValidity();
        if ( isValid === false)  {
            //console.log('invalid');
            $(submitTarget)
                .val('Missing Data')
                .removeClass('btn-success').addClass('btn-warning')
        } else {
            //console.log('valid');
            $(submitTarget)
                .val('Save Changes')
                .addClass('btn-success')
                .removeClass('btn-warning')
                .attr('disabled', false)
        }
    }

    removeNotices(event) {
        let scope = this.element.parentNode;
        let forms = $(scope).find('.needs-validation');
        $(forms).removeClass('was-validated');
    }

    submit(event,form) {
        event.preventDefault();
        //event.stopImmediatePropagation();

        //console.log(form);

        if ( !form.checkValidity() ){
            return false;
        }

        let url = $(form).attr('action');
        let data = $(form).serialize();
        let me = this.element;
        //console.log(data)
        $.ajax({
            url: url,
            type: 'post',
            data: data,
            beforeSend: () => this._beforeSend(),
            success: function(data) {
                me.outerHTML = data;
                var evt = $.Event('householdsChanged');
                $(window).trigger(evt);
            }

        })
    }

    _beforeSend() {
        $(this.submitTarget)
            .val('saving...')
            .attr('disabled', true)
    }

}