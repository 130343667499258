
import { Application } from "stimulus"

import { definitionsFromContext } from "stimulus/webpack-helpers"
import * as ActiveStorage from "@rails/activestorage";


const application = Application.start();
const context = require.context("./controllers", true, /\.js$/);
application.load(definitionsFromContext(context));
require("@rails/ujs").start();
require("turbolinks").start();
ActiveStorage.start();
// require("@rails/activestorage").start();

global.$ = global.jQuery = require('jquery');

// require('jquery');
require('webpack-jquery-ui');
require('webpack-jquery-ui/css');
require('inputmask/dist/jquery.inputmask.bundle');

require('bootstrap');
import 'bootstrap/dist/css/bootstrap.min.css';

require('dropzone');
import 'dropzone/dist/dropzone.css'

require('js-year-calendar');
// require('inputmask/dist');
//import 'inputmask/dist/jquery.inputmask.bundle'
import 'inputmask/css/inputmask.css'
