import { Controller } from "stimulus"

export default class extends Controller {

    static targets = ['card', 'cancelSave', 'submit', 'edit' ];

    connect() {
        console.log('homeless_controller connected');
        $(this.scope.element).find('[data-toggle="tooltip"]').tooltip({
                html: true,
                placement: 'bottom'
            }
        );


        $('a.help-dialog-link').on('click', function(event){
            event.stopPropagation();
        });

        $('.help-dialog').on('click', function(event){
            event.stopImmediatePropagation();
            var target = $(this).data('target');
            var dialog = $('#'+target+'.explanation');
            if (dialog.dialog('isOpen')){
                dialog.dialog('close');
            } else {
                dialog.dialog('open');
            }
        });

        var _this = this;
        $('input,select,textarea')
            .on('change', function(){
                _this.checkRequired();
                _this.removeNotices();
            });

        this.checkRequired();
        this.removeNotices();
    }

    submitHomeless(event) {
        event.preventDefault();
        let form = this.editTarget;
        this.submit(event,form);
    }

    cancelSave(event) {
        var url = this.cancelSaveTarget.getAttribute('href');
        event.preventDefault();
        fetch(url)
            .then(response => response.text())
            .then(html => {
                $('#homeless-edit').replaceWith(html);
            })
    }

    checkRequired() {
        let submitTarget = this.submitTarget;
        let form = this.editTarget;
        //console.log(form)
        form.classList.remove('was-validated');
        form.classList.add('was-validated');
        let isValid = form.checkValidity();
        if ( isValid === false)  {
            // console.log('invalid');
            $(submitTarget)
                .val('Missing Data')
                .removeClass('btn-success').addClass('btn-warning')
        } else {
            //console.log('valid');
            $(submitTarget)
                .val('Save Changes')
                .addClass('btn-success')
                .removeClass('btn-warning')
                .attr('disabled', false)
        }
    }

    removeNotices(event) {
        let scope = this.element.parentNode;
        let forms = $(scope).find('.needs-validation');
        $(forms).removeClass('was-validated');
    }

    submit(event,form) {
        event.preventDefault();
        //event.stopImmediatePropagation();

        //console.log(form);

        if ( !form.checkValidity() ){
            return false;
        }

        let url = $(form).attr('action');
        let data = $(form).serialize();
        //console.log(data)
        $.ajax({
            url: url,
            type: 'patch',
            data: data,
            beforeSend: () => this._beforeSend(),
            success: function(data) {
                $('#homeless-edit').replaceWith(data);
                var evt = $.Event('homelessChanged');
                $(window).trigger(evt);
            }

        })
    }

    handleNarrative(event){
        var non_homeless = ["Resides at a permanent home address", "Military assigned/provided temporary housing"];
        var val = $('#reg_living_situation option:selected').val();
        if(non_homeless.indexOf(val) > -1){ // non-homeless options
           $('#narrative').addClass('hidden')
        } else {
            $('#narrative').removeClass('hidden')
        }
    }

    _beforeSend() {
        $(this.submitTarget)
            .val('saving...')
            .attr('disabled', true)
    }

}