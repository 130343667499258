import { Controller } from "stimulus"

export default class extends Controller {

    static targets = [ 'submit', 'username' ];

    connect() {
        console.log('guardian loaded...');
        $('#changePassword').on('show.bs.modal', function(){
            $('#changePassword input').each(function(){
                $(this).val('');
            });
            $('#pwChangeSuccess').hide();
            $('#cancel_button').addClass('btn-secondary').removeClass('btn-success').html('Done!');
            $('#tryChangePassword,#pwChangeForm').show();

        });

        $('.modal input').blur(function(){
            // clear any messages from password fields
            $('#current_password, #guardian_password, #guardian_password_confirmation').attr('data-toggle',"").attr('data-placement',"").attr('title','').attr('data-original-title','');

            $('#changePassword .required').filter(function() { return $(this).val() == "" }).each(function(){$(this).addClass('unfilled_required').removeClass('filled_required')});
            $('#changePassword .required').filter(function() { return $(this).val() != ""  }).each(function(){$(this).addClass('filled_required').removeClass('unfilled_required')});


            if ( $('#guardian_password').val().length > 0 && $('#guardian_password').val().length < 8 ) {
                var message = "Passwords must be at least 8 characters";
                $('#guardian_password').attr('data-toggle',"tooltip").attr('data-placement',"right").attr('title',message).attr('data-original-title',message).tooltip('show');
                var tip = $('#guardian_password').attr('aria-describedby');
                $('#guardian_password').addClass('unfilled_required').removeClass('filled_required');
                $('#guardian_password').addClass('unfilled_required').removeClass('filled_required');
                $('#'+tip+' .tooltip-inner').removeClass('tooltip_success').addClass('tooltip_error').html(message);
                $('#'+tip+' .arrow').removeClass('tooltip_arrow_success').addClass('tooltip_arrow_error');
                return false;
            } else if ( $('#guardian_password_confirmation').val().length > 0 && $('#guardian_password').val() != $('#guardian_password_confirmation').val() ) {
                var message = "Passwords don't match!";
                $('#guardian_password').attr('data-toggle',"tooltip").attr('data-placement',"right").attr('title',message).attr('data-original-title',message).tooltip('show');
                $('#guardian_password_confirmation').attr('data-toggle',"tooltip").attr('data-placement',"right").attr('title',message).attr('data-original-title',message).tooltip('show');
                var tip1 = $('#guardian_password').attr('aria-describedby');
                $('#'+tip1+' .tooltip-inner').removeClass('tooltip_success').addClass('tooltip_error').html(message);
                $('#'+tip1+' .arrow').removeClass('tooltip_arrow_success').addClass('tooltip_arrow_error');
                var tip2 = $('#guardian_password_confirmation').attr('aria-describedby');
                $('#'+tip2+' .tooltip-inner').removeClass('tooltip_success').addClass('tooltip_error').html(message);
                $('#'+tip2+' .arrow').removeClass('tooltip_arrow_success').addClass('tooltip_arrow_error');
                $('#guardian_password').addClass('unfilled_required').removeClass('filled_required');
                $('#guardian_password_confirmation').addClass('unfilled_required').removeClass('filled_required');
                return false;
            }

        });

        $('#tryChangePassword').click( function(){

            $('#changePassword .required').filter(function() { return $(this).val() === "" }).each(function(){$(this).addClass('unfilled_required').removeClass('filled_required')});
            $('#changePassword .required').filter(function() { return $(this).val() !== "" }).each(function(){$(this).addClass('filled_required').removeClass('unfilled_required')});

            if ($('#changePassword .unfilled_required').length > 0) {return false;}
            $.ajax({
                type: 'POST',
                url: "/guardians/change_password",
                data: {
                    current_password: $('#current_password').val(),
                    password: $('#guardian_password').val(),
                    password_confirmation: $('#guardian_password_confirmation').val()
                },
                success: function(data){
                    console.log(data);
                    if (data.status != 'ok'){
                        $('#current_password').addClass('unfilled_required').removeClass('filled_required').attr('data-toggle',"tooltip").attr('data-placement',"right").attr('title',data.message).attr('data-original-title',data.message).tooltip('show');
                        var tip = $('#current_password').attr('aria-describedby');
                        $('#'+tip+' .tooltip-inner').removeClass('tooltip_success').addClass('tooltip_error').html(data.message);
                        $('#'+tip+' .arrow').removeClass('tooltip_arrow_success').addClass('tooltip_arrow_error');
                    } else {
                        $('#pwChangeSuccess').show();
                        $('#cancel_button').removeClass('btn-secondary').addClass('btn-success').html('Done!');
                        $('#tryChangePassword,#pwChangeForm').hide();
                    }
                },
                failure: function(data){
                }
            });

        });


        $(window).keydown(function(event){
            if(event.keyCode === 13) {
                event.preventDefault();
                return false;
            }
        });


    }

    checkPasswords(event){
        // clear any messages from password fields
        $('#guardian_password, #guardian_password_confirmation').attr('data-toggle',"").attr('data-placement',"").attr('title','').attr('data-original-title','');

        if ( $('#guardian_password').val().length > 0 && $('#guardian_password').val().length < 8 ) {
            var message = "Passwords must be at least 8 characters";
            $('#guardian_password').attr('data-toggle',"tooltip").attr('data-placement',"right").attr('title',message).attr('data-original-title',message).tooltip('show');
            var tip = $('#guardian_password').attr('aria-describedby');
            $('#guardian_password').addClass('unfilled_required').removeClass('filled_required');
            $('#guardian_password').addClass('unfilled_required').removeClass('filled_required');
            $('#'+tip+' .tooltip-inner').removeClass('tooltip_success').addClass('tooltip_error').html(message);
            $('#'+tip+' .arrow').removeClass('tooltip_arrow_success').addClass('tooltip_arrow_error');
        } else if ( $('#guardian_password_confirmation').val().length > 0 && $('#guardian_password').val() != $('#guardian_password_confirmation').val() ) {
            var message = "Passwords don't match!";
            $('#guardian_password').attr('data-toggle',"tooltip").attr('data-placement',"right").attr('title',message).attr('data-original-title',message).tooltip('show');
            $('#guardian_password_confirmation').attr('data-toggle',"tooltip").attr('data-placement',"right").attr('title',message).attr('data-original-title',message).tooltip('show');
            var tip1 = $('#guardian_password').attr('aria-describedby');
            $('#'+tip1+' .tooltip-inner').removeClass('tooltip_success').addClass('tooltip_error').html(message);
            $('#'+tip1+' .arrow').removeClass('tooltip_arrow_success').addClass('tooltip_arrow_error');
            var tip2 = $('#guardian_password_confirmation').attr('aria-describedby');
            $('#'+tip2+' .tooltip-inner').removeClass('tooltip_success').addClass('tooltip_error').html(message);
            $('#'+tip2+' .arrow').removeClass('tooltip_arrow_success').addClass('tooltip_arrow_error');
            $('#guardian_password').addClass('unfilled_required').removeClass('filled_required');
            $('#guardian_password_confirmation').addClass('unfilled_required').removeClass('filled_required');
        }


    }

    checkRequired(event) {
        let form = $('.needs-validation')[0];
        form.classList.remove('was-validated');

        //Require at least one phone number
        var matchingFields = $(form).find('input[type=tel]');
        var totalFields = matchingFields.length;
        var emptyFields = Array.prototype.filter.call(matchingFields, function(field){
            return $(field).val().length === 0;
        }, false);
        if (totalFields === emptyFields.length) {
            matchingFields.each(function(){
                this.setCustomValidity('At least one phone number must be provided.')
            });
        } else {
            matchingFields.each(function(){
                this.setCustomValidity('')
            });
        }

        form.classList.add('was-validated');
        let isValid = form.checkValidity();
        if ( isValid === false)  {
            $(this.submitTarget)
                .val('Missing Data')
                .removeClass('btn-success').addClass('btn-warning')
        } else {
            console.log('valid');
            $(this.submitTarget)
                .val('Save')
                .addClass('btn-success').removeClass('btn-warning')
        }

    }

    removeNotices(event) {
        let form = $('.needs-validation');
        $(form).removeClass('was-validated');
    }



    checkUsername(event){
        console.log('checking username...');
        let username = this.usernameTarget.value;
        if (username.length > 0) {
            $.get("/check_username?username="+username, function(data){
                var error = data['error'];
                var message = data['message'];
                if (error) {
                    $('#guardian_username')[0].setCustomValidity(message);
                    $('#guardian_username')
                        .attr('data-toggle',"tooltip")
                        .attr('data-placement',"right")
                        .attr('title',message)
                        .attr('data-original-title',message)
                        .tooltip('show');
                    var tip = $('#guardian_username').attr('aria-describedby');
                    $('#'+tip+' .tooltip-inner').removeClass('tooltip_success').addClass('tooltip_error').html(message);
                    $('#'+tip+' .arrow').removeClass('tooltip_arrow_success').addClass('tooltip_arrow_error');
                } else {
                    $('#guardian_username')[0].setCustomValidity('');
                    $('#guardian_username')
                        .attr('data-toggle',"tooltip")
                        .attr('data-placement',"right")
                        .attr('title',message)
                        .attr('data-original-title',message)
                        .tooltip('show');
                    var tip = $('#guardian_username').attr('aria-describedby');
                    $('#'+tip+' .tooltip-inner').addClass('tooltip_success').removeClass('tooltip_error').html(message);
                    $('#'+tip+' .arrow').addClass('tooltip_arrow_success').removeClass('tooltip_arrow_error');
                }
            })
        }
    };

}