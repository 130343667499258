import { Controller } from "stimulus"

export default class extends Controller {

  static targets = ["section"];

  connect() {
    console.log('federal_programs_controller connected');
    let reg_id = window.location.pathname.replace(/[^\d]/g, '');

    $(this.scope.element).find('[data-toggle="tooltip"]').tooltip({
          html: true,
          placement: 'bottom'
        }
    );
    $('.help-dialog').on('click', function(event){
      event.stopImmediatePropagation();
      var target = $(this).data('target');
      var dialog = $('#'+target+'.explanation');
      if (dialog.dialog('isOpen')){
          dialog.dialog('close');
      } else {
          var chars = dialog.dialog().html().length;
          var width = chars/30 * 20;
          var win = $(window).width()-10;
          if (chars > 500 && width < win){
              dialog.dialog({width: width+'px'});
          }
          dialog.dialog('open');
      }
    });

    $(this.element).on('mouseenter', function (event) {
      $('#progress .nav-item').removeClass('active');
      $('#progress .nav-item[data-section=federal_programs]').addClass('active');
    });

    $(window).on('contactsChanged householdsChanged demographicsChanged', function (event) {
      console.log('federal_programs_controller detected event: ' + event.type);

      var url = '/regs/' + reg_id + '/federal_programs';
      // console.log(url);
      fetch(url, {
          credentials: 'include',
      })
          .then(response => response.text())
          .then(html => {
            //console.log(html);
            $('#federal_programs').html(html)
          });


      if ($('#federal_programs-wrapper').is(':hidden')) {
        $('#federal_programs-wrapper').removeClass('hidden').slideDown();
        $('#federal_programs-nav').removeClass('hidden').slideDown();
      }
    });

  }

    displayEditHomeless(event) {
        var target = $(event.target).closest('.editable');
        var url = target.attr('data-url');
        fetch(url, {
            credentials: 'include',
        })
            .then(response => response.text())
            .then(html => {
                $('#homeless-button').replaceWith(html);
            })
    }

    displayEditANE(event) {
        var target = $(event.target).closest('.editable');
        var url = target.attr('data-url');
        fetch(url, {
            credentials: 'include',
        })
            .then(response => response.text())
            .then(html => {
                $('#ane-button').replaceWith(html);
            })
    }

    displayEditEducation(event) {
        var target = $(event.target).closest('.editable');
        var url = target.attr('data-url');
        fetch(url, {
            credentials: 'include',
        })
            .then(response => response.text())
            .then(html => {
                $('#education-button').replaceWith(html);
            })
    }


    displayEditESL(event) {
        var target = $(event.target).closest('.editable');
        var url = target.attr('data-url');
        fetch(url, {
            credentials: 'include',
        })
            .then(response => response.text())
            .then(html => {
                $('#esl-button').replaceWith(html);
            })
    }


    displayEditMigrant(event) {
        var target = $(event.target).closest('.editable');
        var url = target.attr('data-url');
        fetch(url, {
            credentials: 'include',
        })
            .then(response => response.text())
            .then(html => {
                $('#migrant-button').replaceWith(html);
            })
    }

    displayEditSped(event) {
        var target = $(event.target).closest('.editable');
        var url = target.attr('data-url');
        fetch(url, {
            credentials: 'include',
        })
            .then(response => response.text())
            .then(html => {
                $('#sped-button').replaceWith(html);
            })
    }



}
