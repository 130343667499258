import { Controller } from "stimulus"

export default class extends Controller {

    static targets = [ "section"];

    connect() {
        console.log('households_controller connected');
        let reg_id = window.location.pathname.replace(/[^\d]/g,'');

        $(this.scope.element).find('[data-toggle="tooltip"]').tooltip({
                html: true,
                placement: 'bottom'
            }
        );

        $(window).on('contactsChanged', function(event){
          console.log('households_controller detected event: '+event.type);

            var url = '/regs/'+reg_id+'/households';
            // console.log(url);
            fetch(url, {
                credentials: 'include',
            })
                .then(response => response.text())
                .then(html => {
                    //console.log(html);
                    $('#households').html(html);
                });


        if ($('#family-wrapper').is(':hidden')){
          $('#family-wrapper').removeClass('hidden').slideDown();
          $('#family-nav').removeClass('hidden').slideDown();

          }
        });

    }

    displayHouseholds(reg_id) {
        var url = '/regs/'+reg_id;
        //console.log(url);
        fetch(url, {
            credentials: 'include',
        })
            .then(response => response.text())
            .then(html => {
                this.element.innerHTML = html
            })
    }

    displayEdit(event) {
        var target = $(event.target).closest('.editable');
        var url = target.attr('data-url');
        fetch(url, {
            credentials: 'include',
        })
            .then(response => response.text())
            .then(html => {
                target.replaceWith(html)
            })
    }


}