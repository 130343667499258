import { Controller } from "stimulus"

export default class extends Controller {

    static targets = [ "edit", 'cancelSave', 'submit', 'permissions' ];

    connect() {
        console.log('contact_controller connected');

        $(":input").inputmask();

        $.ajaxSetup({
            headers: {
                'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content')
            }
        });


        $(this.permissionsTarget).find('.form-group.row').each(function(){
            let icon = $(this).find('.fa');
            let field = $(this).find('input[type=checkbox]');
            if (field.prop("checked")) {
                icon.removeClass('text-faded').addClass('text-info');
            } else {
                icon.addClass('text-faded').removeClass('text-info');
            }
        });


        $('a.help-dialog-link').on('click', function(event){
            event.stopPropagation();
        });

        $('.help-dialog').on('click', function(event){
            event.stopImmediatePropagation();
            var target = $(this).data('target');
            var dialog = $('#'+target+'.explanation');
            if (dialog.dialog('isOpen')){
                dialog.dialog('close');
            } else {
                dialog.dialog('open');
            }
        });

        var _this = this;
        $('input,select,textarea')
            .on('change', function(){
                _this.checkRequired();
                _this.removeNotices();
            });



        this.checkRequired();
        this.removeNotices();

    }

    submitContact(event) {
        event.preventDefault();
        let form = $(this.element);
        this.submit(event,form);
    }

    cancelContact(event) {
        var url = this.cancelSaveTarget.getAttribute('href');
        //console.log(url);
        event.preventDefault();
        if ( url.includes('new_contact')) {
            event.preventDefault();
            this.element.parentNode.remove();
        } else {
            fetch(url, {
                credentials: 'include',
            })
                .then(response => response.text())
                .then(html => {
                    this.element.parentNode.outerHTML = html
                })
        }
    }

    checkRequired(event) {
        let scope = this.element.parentNode;
        let submitTarget = this.submitTarget;
        let forms = $(scope).find('.needs-validation');


        let validForms = Array.prototype.filter.call(forms, function(form) {
            form.classList.remove('was-validated');

            $(form).find('input,select,textarea').filter('[required]:hidden').attr('disabled', true);

            //Require at least one phone number
            var matchingFields = $(form).find('input[type=tel]');
            var totalFields = matchingFields.length;
            var emptyFields = Array.prototype.filter.call(matchingFields, function(field){
                return $(field).val().length === 0;
            }, false);
            if (totalFields === emptyFields.length) {
                matchingFields.each(function(){
                    this.setCustomValidity('At least one phone number must be provided.')
                });
            } else {
                matchingFields.each(function(){
                    this.setCustomValidity('')
                });
            }

            //Require at least one permission checkmark
            let target = $('.permissions');
            if ((target).find('input:checked').length === 0) {
                target.addClass('invalid-entry');
                $(target).find('.invalid-feedback').show();
                $(target).find('input[type=checkbox]').each(function () {
                    this.setCustomValidity('At least one permission number must be provided.')
                })
            } else {
                target.removeClass('invalid-entry');
                $(target).find('.invalid-feedback').hide();
                $(target).find('input[type=checkbox]').each(function () {
                    this.setCustomValidity('')
                })
            }

            //Interrelationships

            //Require email if data_access is checked
            let email = $(form).find("input#contact_email").get(0);
            let data_access = $(form).find("input[type='checkbox'][id$='data_access']").get(0);
            //console.log($(email).val().length);
            if ($(email).val().length === 0 && $(data_access).prop('checked')) {
                email.setCustomValidity('An email address is required');
                data_access.setCustomValidity('An email address is required');
                // $(data_access).closest('.form-group').find('.invalid-tooltip').show();
            } else {
                email.setCustomValidity('');
                data_access.setCustomValidity('');
                // $(data_access).closest('.form-group').find('.invalid-tooltip').hide();
            }

            //require addresses if 'lives-with' is checked
            let lives_with = $(form).find("input[type='checkbox'][id$='lives_with']").get(0);
            let receives_mail = $(form).find("input[type='checkbox'][id$='receives_mail']").get(0);
            if ($(lives_with).prop('checked') || $(receives_mail).prop('checked')){
                let req_addresses = $(form).find('.required_address');
                let no_addresses = $(form).find('.no-addresses');
                if (no_addresses.length > 0){
                    // $(no_addresses).click();
                    if ($(lives_with).prop('checked')) {
                        lives_with.setCustomValidity('An address is required if this contact lives with this student')
                    }
                    if ($(receives_mail).prop('checked')) {
                        receives_mail.setCustomValidity('An address is required if this contact receives mail for this student')
                    }
                }
                if (req_addresses.length > 0) {
                    let blanks = req_addresses.filter(function () {
                        return $(this).text().length === 0
                    });
                    // console.log('doing nothing!');
                    // if (blanks.length > 0){
                    //     $(form).find('.edit-addresses').click();
                    // }
                } else {
                    // console.log('doing something!');

                    $(form).find('[id*="contact_addresses_attributes"]').filter(function () {
                        return !$(this).attr('optional');
                    }).each(function () {
                        $(this).prop('required',true)
                    });
                }
            } else {
                $(form).find('[id*="contact_addresses_attributes"]').filter(function () {
                    return !$(this).attr('optional');
                }).each(function () {
                    this.removeProp('required')
                });
            }

            //Validate Addresses
            //Pysical

            let filledPhysical = $(form).find('[id*="contact_addresses_attributes_0_"]').filter(function() {
                return this.value.length > 0 && this.type !== 'hidden' && $(this).is(':visible')
            });
            if (filledPhysical.length > 0){ //At least one field has data, all non-optional fields must be required
                $(form).find('[id*="contact_addresses_attributes_0_"]').filter(function() {
                    this.setCustomValidity(''); //first clear any old errors
                    return !$(this).attr('optional') && this.value.length === 0;
                }).each(function(){this.setCustomValidity('This field is required')});
            } else {
                $(form).find('[id*="contact_addresses_attributes_0_"]').each(function(){this.setCustomValidity('')});
            }

            //Mailing

            let filledMailing = $(form).find('[id*="contact_addresses_attributes_1_"]').filter(function() {
                return this.value.length > 0 && this.type !== 'hidden' && $(this).is(':visible')
            });
            //console.log(filledMailing);
            if (filledMailing.length > 0){ //At least one field has data, all non-optional fields must be required
                $(form).find('[id*="contact_addresses_attributes_1_"]').filter(function() {
                    this.setCustomValidity(''); //first clear any old errors
                    return !$(this).attr('optional') && this.value.length === 0
                }).each(function(){this.setCustomValidity('This field is required')});
            } else {
                $(form).find('[id*="contact_addresses_attributes_1_"]').each(function(){this.setCustomValidity('')});
            }


            form.classList.add('was-validated');

        }, false);
        let isValid = this.element.checkValidity();
        if ( isValid === false)  {
            // console.log('invalid');
            $(submitTarget)
                .val('Missing Data')
                .removeClass('btn-success').addClass('btn-warning')
        } else {
            // console.log('valid');
            $(submitTarget)
                .val('Save')
                .addClass('btn-success').removeClass('btn-warning')
        }


        return validForms.length === forms.length;
    }

    removeNotices(event) {
        let scope = this.element.parentNode;
        let forms = $(scope).find('.needs-validation');
        $(forms).removeClass('was-validated');
    }

    submit(event,form) {
        event.preventDefault();

        if ( !this.element.checkValidity() ){
            return false;
        }

        let url = form.attr('action');
        let data = form.serialize();
        let me = this.element.parentElement;

        $.ajax({
            url: url,
            type: 'post',
            data: data,
            beforeSend: () => this._beforeSend(),
            success: function(data) {
                me.outerHTML = data;
                var evt = $.Event('contactsChanged');
                $(window).trigger(evt);
            }

        })
    }

    updatePermissions(event) {
        event.preventDefault();
        let scope = event.currentTarget.closest('.row');
        let icon = $(scope).find('.fa');
        let field = $(scope).find('input[type=checkbox]');
        let restricted = /restricted/;
        field.prop("checked", !field.prop("checked")); // flip the checked status
        if (field.prop("checked")) {
            icon.removeClass('text-faded').addClass('text-info');

            // if ( restricted.test(field[0].id) ) {
            //     $(scope).closest('.permissions').find('input[type=checkbox]:checked').each(function(){
            //        $(this).prop("checked",false);
            //        $(this).closest('.row').find('.fa').addClass('text-faded').removeClass('text-info');
            //     });
            //     field.prop("checked", !field.prop("checked"));
            // }

        } else {
            icon.addClass('text-faded').removeClass('text-info');
        }
    }

    // private

    _beforeSend() {
        $(this.submitTarget)
            .text('saving...')
            .attr('disabled', true)
    }

}