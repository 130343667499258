import {Controller} from "stimulus";
import {DirectUpload} from "@rails/activestorage";


export default class extends Controller {

    static targets = [ "dropzone", 'files' ];

    uploadFile = (file, url, name, type) => {
        const upload = new DirectUpload(file, url, this);
        const _this = this;
        // console.log(upload)
        upload.create((error, blob) => {
            console.log(blob);
            if (error) {
                console.log(error);
                alert(error);
            } else {
                // Add an appropriately-named hidden input to the form with a
                //  value of blob.signed_id so that the blob ids will be
                //  transmitted in the normal upload flow
                $(_this.dropzoneTarget).find('#uploading').remove();
                const hiddenField = document.createElement('input');
                hiddenField.setAttribute("type", "hidden");
                hiddenField.setAttribute("value", blob.signed_id);
                hiddenField.setAttribute("id", 'uploading');
                hiddenField.name = name
                // console.log(_this.dropzoneTarget)
                _this.dropzoneTarget.appendChild(hiddenField);
                console.log(_this.dropzoneTarget);
                let url = $(_this.dropzoneTarget).attr('action');
                let data = $(_this.dropzoneTarget).serialize();
                console.log(data);
                $.ajax({
                    url: url,
                    type: 'patch',
                    contentType: 'application/x-www-form-urlencoded; charset=UTF-8',
                    data: data,
                    // beforeSend: () => this._beforeSend(),
                    success: function(data) {
                        //console.log(data);
                        let reg_id = window.location.pathname.replace(/[^\d]/g, '');
                        var url = '/regs/'+reg_id+'/show_upload/'+type;
                        $.get({
                            url:url,
                            success: function(html) {
                                $('#'+type+'-button').replaceWith(html);
                                var evt = $.Event('documentationChanged');
                                $(window).trigger(evt);
                            }
                        });
                    }

                })

            }
        });
    };

    connect() {
        var upload_type = $(this.element).attr('data-type');

        const root = this.element.querySelector('form');
        const fileInputField = root.querySelector('input[type="file"]');
        const url = fileInputField.dataset.directUploadUrl;
        const name = fileInputField.name;
        fileInputField.remove();

        $('#'+upload_type+'-dropzone').dropzone(
        {
            url,
            autoDiscover: false,
            autoQueue: false,
            addedfile: (file) => {
                Object.defineProperty(file, 'name', {
                    writable: true,
                    value: file.name.replace(/[^a-z0-9 \.,_-]/gim,"-").trim()
                });
                console.log(file);
                $(root).find('.dz-message').html('<span>Processing...</span>');
                this.uploadFile(file, url, name, upload_type);
            }
        });
    }


    // directUploadWillStoreFileWithXHR(request) {
    //     request.upload.addEventListener("progress",
    //         event => this.directUploadDidProgress(event));
    // }
    //
    // directUploadDidProgress(event) {
    //     const root = this.element;
    //     root.querySelector('.progress .progress-bar').style.width = `${event.loaded * 100 / event.total}%`;
    // }


    deleteDocument(event) {
        var id = $(event.target).attr('data-id');
        var upload_type = $(event.target).attr('data-type');
        var reg_id = window.location.pathname.replace(/[^\d]/g, '');
        var csrf = document.querySelector('meta[name="csrf-token"]').getAttribute('content');
        $.ajax({
            url: '/attachment/'+id,
            type: 'delete',
            data: {authenticity_token: csrf},
            success: function(data) {
                if (upload_type === 'photo') {
                    $('#photo').replaceWith('<img src="/assets/no_image.jpg">');
                } else {
                    var url = '/regs/' + reg_id + '/show_upload/' + upload_type;
                    $.get({
                        url: url,
                        success: function (html) {
                            $('#' + upload_type + '-button').replaceWith(html);
                            var evt = $.Event('documentationChanged');
                            $(window).trigger(evt);
                        }
                    });
                }
            }
        })

    }

    showButton(event) {
        var type = $(event.target).attr('data-type');
        var reg_id = window.location.pathname.replace(/[^\d]/g, '');
        var url = '/regs/'+reg_id+'/documentation/'+type;
        $.get({
            url:url,
            success: function(html) {
                var evt = $.Event('documentationChanged');
                $(window).trigger(evt);

                $('#'+type+'-button').replaceWith(html);
            }
        });
    }

}
