import { Controller } from "stimulus"

export default class extends Controller {

    static targets = [ 'edit', 'section' ];

    connect() {
        console.log('contacts_controller connected');
        $(this.scope.element).find('[data-toggle="tooltip"]').tooltip({
                html: true,
                placement: 'bottom'

            }
        )
        $('.hover-info')
            .on('mouseenter', function(){
                var note = $(this).find('.fa').attr('note');
                //console.log(note);
                $(this).closest('.editable').find('.hover-note').html(note);
            })
            .on('mouseleave', function(){
                $(this).closest('.editable').find('.hover-note').html('');
            });


    }

    displayOptions(event) {
        // console.log(this.element)
        $(this.element).find('.options-button-list').slideDown();
    }

    hideOptions(event) {
        // console.log(this.element)
        $(this.element).find('.options-button-list').slideUp();
    }


    displayNew(event) {

        //check to see if we already have a form on the page
        if ($('#new_contact').length > 0) {
            $('#new_contact').scrollToElement({behavior: "smooth", block: "start"});
            return false;
        }

        var url = $(event.target).attr('data-url');

        //get the new form element and load it on the page
        fetch(url, {
            credentials: 'include',
        })
            .then(response => response.text())
            .then(html => {
                $('#contacts-list').append(html);
                if (this.element.classList.contains('options-button-list-item')){
                    $(this.element).fadeOut();
                }
                $('body').animate({
                    scrollTop: $('#contacts-list').children('.card').last().top
                }, 2000);
                // $('#contacts-list:last-child').scrollToElement({behavior: "smooth", block: "start"});
            })

    }


    displayEdit() {
        // console.log(this.data);
        var url = this.data.get('url');
        fetch(url, {
            credentials: 'include',
        })
            .then(response => response.text())
            .then(html => {
                this.element.outerHTML = html;
            })
    }


}