import { Controller } from "stimulus"

export default class extends Controller {

    static targets = ['card', 'cancelSave', 'submit', 'edit', 'show' ];

    connect() {
        console.log('signature_controller connected');

        console.log(incomplete);

        $(this.scope.element).find('[data-toggle="tooltip"]').tooltip({
                html: true,
                placement: 'bottom'
            }
        );
        $('.help-dialog').on('click', function(event){
            event.stopImmediatePropagation();
            var target = $(this).data('target');
            var dialog = $('#'+target+'.explanation');
            if (dialog.dialog('isOpen')){
                dialog.dialog('close');
            } else {
                dialog.dialog('open');
            }
        });

        $.ajaxSetup({
            headers: {
                'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content')
            }
        });

        // $('#missing-required-docs').find('input,select').filter('[required]').each(function(){
        //     if ($(this).is(':hidden')) {
        //         $(this).attr('disabled', true)
        //     }
        // });

        var theDiv = $('#missing-required-docs');
        if ( $(theDiv).find('.missing_doc input').not(':disabled').length > 0){
            theDiv.removeClass('hidden');
        } else {
            theDiv.addClass('hidden');

        }

        $(window).on('documentationChanged', function(event) {
            console.log('signature_controller detected event: ' + event.type);
            console.log(event.target_div);
            let target = $(event.target_div);
            console.log(target);
            console.log(event.status);
            if ( event.status === 'complete'){
                // console.log('complete')
                target.addClass('hidden');
                target.find('input').attr('disabled',true);
            } else {
                // console.log('incomplete')
                target.removeClass('hidden');
                target.find('input').removeAttr('disabled');
            }

            console.log($(theDiv).find('.missing_doc input').not(':disabled'));
            console.log($(theDiv).find('.missing_doc input').not(':disabled').length);
            if ( $(theDiv).find('.missing_doc input').not(':disabled').length > 0){
               $('#missing-required-docs').removeClass('hidden');
            } else {
                $('#missing-required-docs').addClass('hidden');

            }
        });

        $(window).on('statusUpdate', function(event) {
            // console.log('signature_controller detected event: ' + event.type + ' for: ' + event.target_div);
            // console.log(event);
            if (event.status.match(/section-complete/)){
                var search_term = event.item;

                for (var i=incomplete.length-1; i>=0; i--) {
                    if (incomplete[i] === search_term) {
                        incomplete.splice(i, 1);
                    }
                }
            }
            console.log(incomplete);
            //console.log(incomplete_documentation);
            if (incomplete.length === 0){
               $('#signature-wrapper,#signature,#signature-nav').removeClass('hidden');
            } else {
                $('#signature-wrapper,#signature,#signature-nav').addClass('hidden');

            }

        });



        var _this = this;
        $('input,select,textarea')
            .on('change', function(){
                _this.checkRequired();
                _this.removeNotices();
            });


        this.checkRequired();
        this.removeNotices();
    }


    displayEditSignature(event) {
        var target = $(event.target).closest('.editable');
        var url = target.attr('data-url');
        fetch(url, {
            credentials: 'include',
        })
            .then(response => response.text())
            .then(html => {
                $('#signature-button').replaceWith(html)
            })
    }



    submitFORM(event) {
        event.preventDefault();
        let form = this.editTarget;
        this.submit(event,form);
    }

    cancelSave(event) {
        var url = this.cancelSaveTarget.getAttribute('href');
        event.preventDefault();
        fetch(url)
            .then(response => response.text())
            .then(html => {
                $('#signature').html(html);
            })
    }

    checkRequired() {
        let submitTarget = this.submitTarget;
        let form = this.editTarget;

        //console.log(form)
        form.classList.remove('was-validated');
        form.classList.add('was-validated');
        let isValid = form.checkValidity();
        if ( isValid === false)  {
            console.log('invalid');
            $(submitTarget)
                .val('Missing Data')
                .removeClass('btn-success').addClass('btn-warning')
        } else {
            console.log('valid');
            $(submitTarget)
                .val('Sign Application')
                .addClass('btn-success')
                .removeClass('btn-warning')
                .attr('disabled', false)
        }
    }

    removeNotices(event) {
        let scope = this.element.parentNode;
        let forms = $(scope).find('.needs-validation');
        $(forms).removeClass('was-validated');
    }

    submit(event,form) {
        event.preventDefault();
        //event.stopImmediatePropagation();

        //console.log(form);

        if ( !form.checkValidity() ){
            return false;
        }

        let url = $(form).attr('data-action');
        let data = $(form).serialize();
        //console.log(data)
        $.ajax({
            url: url,
            type: 'patch',
            credentials: 'include',
            data: data,
            beforeSend: () => this._beforeSend(),
            success: function(data) {
                $('#signature').html(data);
                var evt = $.Event('signatureChanged');
                $(window).trigger(evt);
            }

        })
    }

    _beforeSend() {
        $(this.submitTarget)
            .val('signing...')
            .attr('disabled', true)
    }

}