import { Controller } from "stimulus"

export default class extends Controller {

    static targets = [ "edit", 'cancelSave', 'submit', 'permissions' ];

    connect() {
        console.log('ferpa_controller connected');

        $(this.scope.element).find('[data-toggle="tooltip"]').tooltip({
                html: true,
                placement: 'bottom'
            }
        );

        $(this.editTarget).find('.form-group.row-hover').each(function(){
            let icon = $(this).find('.fa');
            let field = $(this).find('input[type=checkbox]');
            if (field.prop("checked")) {
                icon.removeClass('text-faded').addClass('text-info');
            } else {
                icon.addClass('text-faded').removeClass('text-info');
            }
        });

        $('.help-dialog,.help-dialog-link').on('click', function(event){
            event.stopImmediatePropagation();
            var target = $(this).data('target');
            var dialog = $('#'+target+'.explanation');
            if (dialog.dialog('isOpen')){
                dialog.dialog('close');
            } else {
                dialog.dialog('open');
            }
        });

        this.checkRequired();
        this.removeNotices();

    }

    submitForm(event) {
        event.preventDefault();
        let form = this.editTarget;
        this.submit(event,form);
    }

    cancelSave(event) {
        var url = this.cancelSaveTarget.getAttribute('href');
        event.preventDefault();
        fetch(url, {
            credentials: 'include',
        })
            .then(response => response.text())
            .then(html => {
                $('#ferpa').html(html);
            })
    }

    checkRequired(event) {
        let submitTarget = this.submitTarget;
        let form = this.editTarget;

        //console.log(form)
        form.classList.remove('was-validated');
        form.classList.add('was-validated');
        let isValid = form.checkValidity();
        if ( isValid === false)  {
            // console.log('invalid');
            $(submitTarget)
                .val('Missing Data')
                .removeClass('btn-success').addClass('btn-warning')
        } else {
            // console.log('valid');
            $(submitTarget)
                .val('Save Changes')
                .addClass('btn-success')
                .removeClass('btn-warning')
                .attr('disabled', false)
        }
    }

    removeNotices(event) {
        let scope = this.element.parentNode;
        let forms = $(scope).find('.needs-validation');
        $(forms).removeClass('was-validated');
    }

    submit(event,form) {
        event.preventDefault();

        if ( !form.checkValidity() ){
            return false;
        }

        let reg_id = window.location.pathname.replace(/[^\d]/g, '');
        var url = '/regs/'+reg_id+'/update_ferpa';
        let data = $(form).serialize();
        //console.log(data)
        $.ajax({
            url: url,
            type: 'patch',
            data: data,
            beforeSend: () => this._beforeSend(),
            success: function(data) {
                $('#ferpa').html(data);
                var evt = $.Event('ferpaChanged');
                $(window).trigger(evt);
            }

        })
    }

    updatePermissions(event) {
        event.preventDefault();
        let scope = event.currentTarget;
        let icon = $(scope).find('.fa');
        let field = $(scope).find('input[type=checkbox]');
        field.prop("checked", !field.prop("checked")); // flip the checked status
        if (field.prop("checked")) {
            icon.removeClass('text-faded').addClass('text-info');
        } else {
            icon.addClass('text-faded').removeClass('text-info');
        }
    }

    // private

    _beforeSend() {
        $(this.submitTarget)
            .text('saving...')
            .attr('disabled', true)
    }

}