import { Controller } from "stimulus"
import {DirectUpload} from "@rails/activestorage";

export default class extends Controller {

    static targets = [ "photo", "edit" , 'cancelSave', 'submit'];

    connect() {
        console.log('demographics_controller connected');

        $(":input").inputmask();

        $(this.scope.element).find('[data-toggle="tooltip"]').tooltip({
                html: true,
                placement: 'bottom'
            }
        );
        $('a.help-dialog-link').on('click', function(event){
            event.stopPropagation();
        });

        $('.help-dialog').on('click', function(event){
            event.stopImmediatePropagation();
            var target = $(this).data('target');
            var dialog = $('#'+target+'.explanation');
            if (dialog.dialog('isOpen')){
                dialog.dialog('close');
            } else {
                dialog.dialog('open');
            }
        });

        $('#reg_dob').on('change', function(){
          if ( $('#reg_ps_student_id').val().length === 0 ){
              var last_name = $('#reg_last_name').val();
              var bday = $('#reg_dob').val();

              $.get('/check_for_possible_match/'+last_name+'/'+bday, function(result) {
                  console.log(result.result);
                  if (result.result === true) {
                      $('#prev-stud').addClass('blink-text')
                      alert("It looks like you're completing a registration for a student who might have been in our district before. Click the button to search for your student and pre-fill parts of the form.")
                  }
              })
          }
        });

        $('#reg_grade_level').on('change', function(){
           if ( $(this).find(":selected").text() === 'Kindergarten'){
               $('#preschool').removeClass('hidden');
           } else {
               $('#preschool').addClass('hidden');
           }
        });

        $('#prev-stud').on('click', function(event){
            event.stopImmediatePropagation();
            $(this).removeClass('blink-text');

            var dialog = $('#student-search');
            if (dialog.dialog('isOpen')){
                dialog.dialog('close');
            } else {
                dialog.dialog('open');
            }
        });

        $('#stud-search').on('click', function(event){
            event.stopImmediatePropagation();
            let _this = $(this);
            let old_html = _this.html();
            _this.html('Searching...');
            let snum = $('#snumber').val();
            let bday = $('#bday').val();
            console.log('snum:'+snum+' bday:'+bday);
            if (snum.length > 5 && bday.length > 8){
              $.get('/student_search/'+snum+'/'+bday, function(result){
                 console.log(result);
                 //hidden fields
                 $('#reg_ps_student_id').val(result.ps_student_id);
                 $('#reg_has_previous_fnsbsd_enrollment').val(1);
                 $('#reg_has_previous_education').val(1);
                 $('#reg_previous_fnsbsd_school_name').val(result.prev_school);
                 $('#reg_has_native_blood').val(result.previous_ane);
                 $('#reg_native_tribe').val(result.ane_group);
                 $('#reg_native_village').val(result.tribal_organization);
                 $('#reg_native_address').val(result.group_address);
                 $('#reg_native_city_st_zip').val(result.native_city_st_zip);
                 $('#reg_tribal_member_first_name').val(result.tribal_member_first_name);
                 $('#reg_tribal_member_last_name').val(result.tribal_member_last_name);
                 $('#reg_tribal_member_relationship').val(result.tribal_member_relationship);
                 $('#reg_tribal_membership_number').val(result.tribal_membership_number);
                 $('#reg_date_of_entry_to_us').val(result.date_of_entry_to_us);
                 $('#reg_previous_fnsbsd_school_grade_level').val(result.previous_fnsbsd_school_grade_level);
                 $('#reg_home_language').val(result.home_language);
                 $('#reg_first_language').val(result.first_language);
                 $('#reg_preferred_language').val(result.preferred_language);
                 $('#reg_other_home_language_preferred').val(result.other_home_language_preferred);
                 $('#reg_other_home_language').val(result.other_home_language);
                 $('#reg_foreign_education_grade_levels').val(result.foreign_education_grade_levels);
                 $('#reg_foreign_education_language').val(result.foreign_education_language);
                 $('#reg_foreign_education').val(result.foreign_education);


                 $('#reg_first_name').val(result.first_name).css('color','green');
                 $('#reg_last_name').val(result.last_name).css('color','green');
                 $('#reg_middle_name').val(result.middle_name).css('color','green');
                 $('#reg_preferred_name').val(result.preferred_name).css('color','green');
                 $('#reg_dob').val(result.dob).css('color','green');
                 $('#reg_birth_city').val(result.birth_city).css('color','green');
                 $('#reg_birth_state').val(result.birth_state).css('color','green');
                 $('#reg_birth_country').val(result.birth_country).css('color','green');
                 $('input[name="reg[gender]"][value="' + result.gender + '"]').prop('checked',true).css('color','green');
                 $('input[name="reg[ethnicity]"][value="' + result.ethnicity + '"]').prop('checked',true).css('color','green');
                 result.races.forEach(function(num){
                     $('#reg_races_'+num).prop('checked',true).css('color','green');
                 });
                  $('#student-search').dialog('close');
              }).fail(function(){
                  alert('No former stdudent found with student number: '+snum+' and a birth date of: '+bday);
                  _this.html(old_html);
                  return false
              })
            } else {
              return false;
            }
        });


        var _this = this;
        $('#demographics input,select,textarea')
            .on('change', function(){
                _this.checkRequired();
                _this.removeNotices();
            });

        this.checkRequired();
        this.removeNotices();

    }

    cancelSave(event) {
        var url = this.cancelSaveTarget.getAttribute('href');
        event.preventDefault();
        fetch(url, {
            credentials: 'include',
        })
            .then(response => response.text())
            .then(html => {
                $('#demographics').closest('section').replaceWith(html)
            })
    }

    isValidDate(dateString) {
        // var regEx = /^\d{4}-\d{2}-\d{2}$/;
        var regEx = /^\d{2}-\d{2}-\d{4}$/;
        if(!dateString.match(regEx)) return false;  // Invalid format
        var x = dateString.split('-');
        var y = x[2]+'-'+x[0]+'-'+x[1];
        var d = new Date(y);
        // console.log(y)
        // console.log(d)
        var dNum = d.getTime();
        if(!dNum && dNum !== 0) return false; // NaN value, Invalid date
        return d.toISOString().slice(0,10) === y;
    }




    submitDemographics(event) {
        event.preventDefault();
        let form = this.editTarget;
        this.submit(event,form);
    }

    checkRequired(event) {
        let scope = this.element.parentNode;
        let submitTarget = this.submitTarget;
        let form = $('form.new_reg,form.edit_reg')[0];

        form.classList.remove('was-validated');
        form.classList.add('was-validated');

        if ( $('#reg_grade_level').find(":selected").text() === 'Kindergarten'){
            $('#preschool').removeClass('hidden');
            $('#preschool').find('input').attr('required',true);
        } else {
            $('#preschool').find('input').removeAttr('required');
            $('#preschool').addClass('hidden');
        }

        var dob = $('#reg_dob').val();
        if (this.isValidDate(dob)){
            console.log(dob +' is a valid date');
            $('#reg_dob')[0].setCustomValidity('')
        } else {
            console.log(dob +' is NOT a valid date');
            $('#reg_dob')[0].setCustomValidity('Invalid date')
        }

        let isValid = form.checkValidity();
        if ( isValid === false)  {
            console.log('invalid');
            $(submitTarget)
                .val('Missing Data')
                .removeClass('btn-success').addClass('btn-warning')
        } else {
            //console.log('valid');
            $(submitTarget)
                .val('Save Changes')
                .addClass('btn-success')
                .removeClass('btn-warning')
                .attr('disabled', false)
        }
    }


    updatePrimaryRace(event) {
        let races = $('[name="reg[races][]"]');
        races.each(function(){
            let option = $('#reg_primary_race option[value='+this.value+']');
            if (this.checked){
                option.attr('disabled',false);
            } else {
                option.attr('disabled','disabled');
            }
            if (this.value === '3'){
                $("#reg_ethnicity_hispanic").prop('checked',this.checked);
                $("#reg_ethnicity_non-hispanic").prop('checked',!this.checked)
            }
        });
        if ( $('#reg_primary_race option:selected').attr('disabled') === 'disabled') {
            $('#reg_primary_race').val('');
        }

    }

    updateHispanic(event) {
        //console.log($("input[name='reg[ethnicity]']:checked"). val());
        $('#reg_races_3').prop('checked', $("input[name='reg[ethnicity]']:checked"). val() === 'hispanic');
    }

    updateRaces(event) {
        let val = $('#reg_primary_race option:selected').val();
        $('#reg_races_'+val).prop('checked', true);
    }

    removeNotices(event) {
        let form = $('form.new_reg,form.edit_reg')[0];
        form.classList.remove('was-validated');
    }

    submit(event,form) {
        event.preventDefault();

        if ( !form.checkValidity() ){
            return false;
        }

        // let reload = $(form).attr('data-reload');
        // console.log(reload);

        var url = $(form).attr('action');
        var method = 'post';
        let data = new FormData(form);
        let me = this.element;
        // console.log(url.match(/regs$/))
        // console.log(url.match(/regs$/) === null)
        if ( url.match(/regs$/) === null ) {
            method = 'put'
        }

        $.ajaxSetup({
            headers: {
                'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content')
            }
        });

        console.log('method: '+method);
        $.ajax({
            url: url,
            method: method,
            data: data,
            cache: false,
            contentType: false,
            processData: false,
            beforeSend: () => this._beforeSend(),
            success: function(data) {
                if(method === 'put'){
                    $('#demographics').closest('section').replaceWith(data);
                }
                var evt = $.Event('demographicsChanged');
                $(window).trigger(evt);
            }

        })
    }


    // private

    _beforeSend(xhr) {
        $(this.submitTarget)
            .text('saving...')
            .attr('disabled', true)
    }
}