import { Controller } from "stimulus"

export default class extends Controller {

    static targets = [ "edit", 'cancelSave', 'submit', 'link' ];

    connect() {
        console.log('addresses_controler connected');


        $(this.scope.element).find('[data-toggle="tooltip"]').tooltip({
                html: true,
                placement: 'bottom'

            }
        );

        $('a.help-dialog-link').on('click', function(event){
            event.stopPropagation();
        });

        $('.help-dialog').on('click', function(event){
            event.stopImmediatePropagation();
            var target = $(this).data('target');
            var dialog = $('#'+target+'.explanation');
            if (dialog.dialog('isOpen')){
                dialog.dialog('close');
            } else {
                dialog.dialog('open');
            }
        });

    }

    updateUnknownAddress(event) {
        event.preventDefault();
        var card = $(event.target).closest('.card');
        let field = $(card).find('input[type=checkbox]');
        field.prop("checked", !field.prop("checked")); // flip the checked status
        if (field.prop("checked")) {
            card.find('.address-part').addClass('hidden');
            card.find('textarea').removeClass('hidden');
        } else {
            card.find('.address-part').removeClass('hidden');
            card.find('textarea').addClass('hidden');
        }

    }

    editAddresses(event){
        var url = this.editTarget.getAttribute('href');
        let contact_id = $(event.target).closest('button.list-group-item').attr('data-contactid');
        //console.log($(event.target).closest('button.list-group-item'));
        event.preventDefault();
        fetch(url+'&contact_id='+contact_id, {
            credentials: 'include',
        })
            .then(response => response.text())
            .then(html => {
                this.element.outerHTML = html;
            });
    }


    syncCounterpart(event){
        var link = $(this.editTarget).find('[id*="contact_addresses_linked"]');
        if (link.val() === 'false'){
            return
        }

        var key = event.key;
        var elementId = event.target.id;
        //console.log(elementId)
        var targetId;
        if (elementId.includes('_1_')) { // Editing mailing, don't update physical
           link.val('false');
           //console.log(link);
           var fa_link = link.parent().find('.fa-link');
           //console.log(fa_link);
               fa_link.removeClass('fa-link')
               .addClass('fa-unlink')
               .tooltip('hide')
               .attr('data-original-title',"Mailing and physical addresses are different<hr>Click here to sync physical to mailing.");
               return
        }

        if (elementId.includes('_0_')) {
            targetId = elementId.replace(/_0_/, '_1_');
        } else {
            targetId = elementId.replace(/_1_/, '_0_');
        }
        var targetElement = $(this.editTarget).find('#'+targetId);
        $(targetElement).val(event.target.value);
    }

    toggleLink(event){
        let scope = this.element;

        var element = event.target;
        if($(element).hasClass('fa-link')){
            $(element)
                .removeClass('fa-link')
                .addClass('fa-unlink')
                .tooltip('hide')
                .attr('data-original-title',"Mailing and physical addresses are different<hr>Click here to make them the same.")
                .tooltip('show');
            $(this.editTarget).find('[id*="contact_addresses_linked"]').val(false);
        } else {
            $(element)
                .removeClass('fa-unlink')
                .addClass('fa-link')
                .tooltip('hide')
                .attr('data-original-title',"Mailing and physical addresses are the same<hr>Click here to unlink them if they are different.")
                .tooltip('show');
            $(this.editTarget).find('[id*="contact_addresses_linked"]').val(true);
            let filledPhysical = $(scope).find('[id*="contact_addresses_attributes_0_"]').filter(function() {
                return this.value.length > 0 && this.type !== 'hidden'
            });
            let filledMailing = $(scope).find('[id*="contact_addresses_attributes_1_"]').filter(function() {
                return this.value.length > 0 && this.type !== 'hidden'
            });
            if (filledPhysical.length >= filledMailing.length){
                $(scope).find('[id*="contact_addresses_attributes_0_"]').filter(function() {
                    return this.type !== 'hidden'
                }).each(function(){
                    var source_id = this.id;
                    var destination_id = source_id.replace(/_0_/, '_1_');
                    console.log(scope.id);
                    $('#'+scope.id+' #'+destination_id).val($('#'+source_id).val())
                });
            } else {
                $(scope).find('[id*="contact_addresses_attributes_1_"]').filter(function() {
                    return this.type !== 'hidden'
                }).each(function() {
                    var source_id = this.id;
                    var destination_id = source_id.replace(/_1_/, '_0_');
                    $('#'+scope.id+' #'+destination_id).val($('#'+source_id).val())
                })
            }
        }
    }

    showContent(event){
        $(event.target).find('.hidden').removeClass('hidden');
    }
    hideContent(event){
        $(event.target).find('.list-group').addClass('hidden');
    }
}